<template>
  <BodySimple :loading="firstLoading">
    <template #header>
      <div class="flex items-center">
        <img src="@/assets/images/icons8-group-message-96.png" class="h-20 ml-2" />
        <div class="text-xl font-bold">پیام های ربات در گروه</div>
      </div>
      <h2 class="text-xl font-bold">
        <Icon name="Group"></Icon>
        {{ group.name }}
      </h2>
      <div class="mt-3 text-right" dir="ltr">{{ group.gid }}</div>
    </template>
    <template #default>
      <!-- message helper -->
      <ErrorBox :errors="errors" />
      <div class="p-3 bg-blueGray-100 border border-blueGray-200 rounded-md mb-5">
        <div class="text-lg font-bold">
          <img src="@/assets/images/icons8-rename-96.png" class="inline-block h-20" />
          راهنمای پیام ها
        </div>
        <p>برای نمایش راهنمای پیام ها دکمه زیر را بزنید</p>
        <div>
          <Button
            class="inline-flex text-sm h-8 mt-5"
            color="amber"
            @click="tagHelper = !tagHelper"
          >
            نمایش راهنما
          </Button>
        </div>
        <div class="my-3" v-if="tagHelper">
          <div
            v-for="(item, index) in tags"
            :key="index"
            class="flex gap-2 odd:bg-blueGray-200"
          >
            <div class="p-2 w-36 flex-shrink-0" dir="ltr">{{ item.tag }}</div>
            <div class="p-2">{{ item.info }}</div>
          </div>
          <div class="bg-cyan-600 text-cyan-50 p-2">تگ های زمان و تاریخ</div>
          <div
            v-for="(item, index) in timeTags"
            :key="index"
            class="flex gap-2 odd:bg-blueGray-200"
          >
            <div class="p-2 w-36 flex-shrink-0" dir="ltr">{{ item.tag }}</div>
            <div class="p-2">{{ item.info }}</div>
          </div>
        </div>
      </div>

      <!-- remove message -->

      <MainSettingCard
        :otherGroup="otherGroup"
        :loading="sending"
        @setSettings="submit('remove_setting', $event)"
      >
        <div class="text-lg font-bold">
          <img
            src="@/assets/images/icons8-remove-message-96.png"
            class="inline-block h-20"
          />
          حذف پیام های ربات
        </div>
        <div class="grid xl:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-3 mt-3">
          <MnrNumber
            :min="0"
            :max="59"
            v-model="alertTime.sec"
            title="ثانیه"
            before="ثانیه"
            placeholder="0"
          ></MnrNumber>
          <MnrNumber
            :min="0"
            :max="5"
            v-model="alertTime.min"
            title="دقیقه"
            before="دقیقه"
            placeholder="0"
          ></MnrNumber>
        </div>
        <div class="grid xl:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-3 mt-3">
          <MnrNumber
            v-model="group.del_alert_last"
            :min="0"
            :max="5"
            title="ماندن آخرین پیام ها"
            before="پیام"
            placeholder="0"
          ></MnrNumber>
        </div>
        <div class="mt-3">
          <MnrCheckSlider v-model:checked="group.msg.markdown">
            پیام ها به صورت markdown ارسال شوند
          </MnrCheckSlider>
          <br />
          <a
            href="https://core.telegram.org/bots/api#markdownv2-style"
            class="text-sky-500"
            target="_blank"
          >
            راهنمای markdownV2
          </a>
        </div>

        <template #help>
          <p>
            از این قسمت می‌توانید مشخص کنید که یک پیام چقدر در گروه بماند. همچنین میتوانید
            مشخص کنید که چند پیام آخر در گروه بماند. فقط گروه هایی که قابلیت ویژه دارند
            می‌توانند زمان را کمتر از 1 دقیقه و تعداد پیام را کمتر از 2 انتخاب کنند.
          </p>
          <h2 class="text-lg font-bold mt-3">markdown</h2>
          <p class="mt-2">
            پیشنهاد می‌شود در صورتیکه نمیدانید فرمت markdown چیست این گزینه را فعال نکنید
            چون ممکن است پیام ها به درستی ارسال نشوند.
          </p>
        </template>
      </MainSettingCard>

      <!-- deleted message -->

      <MainSettingCard
        :otherGroup="otherGroup"
        :loading="sending"
        @setSettings="submit('deleted', $event)"
      >
        <div class="text-lg font-bold">
          <img src="@/assets/images/icons8-chat-96.png" class="inline-block h-20" />
          پیام مشاهده پیام های حذف شده
        </div>

        <MnrCheckSlider v-model:checked="group.msg.deleted">
          اضافه کردن نمایش پیام های حذف شده به آخر پیام ها
        </MnrCheckSlider>

        <div class="grid xl:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-3 mt-3">
          <MnrTextarea
            v-model="group.msg.deleted_msg"
            class="col-span-2"
            placeholder="متن پیام را وارد کنید"
          />
        </div>
        <div class="inline-block w-32 mt-3">
          <Button
            class="inline-block h-8"
            color="teal"
            @click="group.msg.deleted_msg = defMsg.deleted_msg"
          >
            پیشفرض
          </Button>
        </div>

        <template #help>
          <p>
            این قابلیت بسیار برای گروه های تخصصی و پرسش و پاسخ مناسب است. با فعال کردن این
            قابلیت زمانی که ربات پیام شخصی را پاک میکنید در آخر پیام ربات متن مشخص شده
            اضافه شده و کاربر با زدن روی آن میتواند پیام حذف شده خود را ببیند. اگر شخص
            زمان زیادی را برای تایپ کردن سوال خود گذاشته بود دیگر مجبور نیست دوباره آن را
            بنویسد
          </p>
          <h2 class="text-lg font-bold mt-3">پیشنهاد تنظیمات توسط سازنده</h2>
          <p>اگر گروه شما تخصصی و پرسش و پاسخ است. حتما این قابلیت را فعال کنید.</p>
        </template>
      </MainSettingCard>

      <!-- enter message -->

      <MainSettingCard
        :otherGroup="otherGroup"
        :loading="sending"
        @setSettings="submit('join', $event)"
      >
        <div class="text-lg font-bold">
          <img src="@/assets/images/icons8-enter-96.png" class="inline-block h-20" />
          پیام ورود به گروه
        </div>

        <div class="grid xl:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-3 mt-3">
          <MnrTextarea
            v-model="group.msg.join"
            class="col-span-2"
            placeholder="متن پیام را وارد کنید"
          />
        </div>
        <div class="inline-block w-32 mt-3">
          <Button
            class="inline-block h-8"
            color="teal"
            @click="group.msg.join = defMsg.join"
          >
            پیشفرض
          </Button>
        </div>

        <template #help>
          <p>این پیام هنگام ورود کاربر به گروه توسط لینک ارسال می‌شود</p>
        </template>
      </MainSettingCard>

      <!-- invated message -->

      <MainSettingCard
        :otherGroup="otherGroup"
        :loading="sending"
        @setSettings="submit('invite', $event)"
      >
        <div class="text-lg font-bold">
          <img src="@/assets/images/icons8-meeting-96.png" class="inline-block h-20" />
          پیام ورود توسط دیگران
        </div>

        <div class="grid xl:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-3 mt-3">
          <MnrTextarea
            v-model="group.msg.invite"
            class="col-span-2"
            placeholder="متن پیام را وارد کنید"
          />
        </div>
        <div class="inline-block w-32 mt-3">
          <Button
            class="inline-block h-8"
            color="teal"
            @click="group.msg.invite = defMsg.invite"
          >
            پیشفرض
          </Button>
        </div>

        <template #help>
          <p>این پیام زمانی که کاربر توسط کاربر دیگر دعوت شود ارسال می‌شود</p>
        </template>
      </MainSettingCard>

      <!-- exit message -->

      <MainSettingCard
        :otherGroup="otherGroup"
        :loading="sending"
        @setSettings="submit('exit', $event)"
      >
        <div class="text-lg font-bold">
          <img src="@/assets/images/icons8-export-96.png" class="inline-block h-20" />
          پیام خروج
        </div>

        <div class="grid xl:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-3 mt-3">
          <MnrTextarea
            v-model="group.msg.left"
            class="col-span-2"
            placeholder="متن پیام را وارد کنید"
          />
        </div>
        <div class="inline-block w-32 mt-3">
          <Button
            class="inline-block h-8"
            color="teal"
            @click="group.msg.left = defMsg.left"
          >
            پیشفرض
          </Button>
        </div>

        <template #help>
          <p>این پیام زمانی که کاربر از گروه خارج می‌شود ارسال می‌شود.</p>
        </template>
      </MainSettingCard>

      <!-- force invite message -->

      <MainSettingCard
        :otherGroup="otherGroup"
        :loading="sending"
        @setSettings="submit('add_force', $event)"
      >
        <div class="text-lg font-bold">
          <img
            src="@/assets/images/icons8-add-user-male-96.png"
            class="inline-block h-20"
          />
          پیام دعوت اجباری
        </div>

        <div class="grid xl:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-3 mt-3">
          <MnrTextarea
            v-model="group.msg.add_force"
            class="col-span-2"
            placeholder="متن پیام را وارد کنید"
          />
        </div>
        <div class="inline-block w-32 mt-3">
          <Button
            class="inline-block h-8"
            color="teal"
            @click="group.msg.add_force = defMsg.add_force"
          >
            پیشفرض
          </Button>
        </div>

        <template #help>
          <p>این پیام زمانی که ربات از کاربر درخواست دعوت اجباری می‌کند ارسال می‌شود.</p>
        </template>
      </MainSettingCard>

      <!-- force invite message for link -->

      <MainSettingCard
        :otherGroup="otherGroup"
        :loading="sending"
        @setSettings="submit('add_force_link', $event)"
      >
        <div class="text-lg font-bold">
          <img
            src="@/assets/images/icons8-add-user-group-woman-man-96_2.png"
            class="inline-block h-20"
          />
          پیام دعوت اجباری برای ارسال لینک
        </div>

        <div class="grid xl:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-3 mt-3">
          <MnrTextarea
            v-model="group.msg.add_force_link"
            class="col-span-2"
            placeholder="متن پیام را وارد کنید"
          />
        </div>
        <div class="inline-block w-32 mt-3">
          <Button
            class="inline-block h-8"
            color="teal"
            @click="group.msg.add_force_link = defMsg.add_force_link"
          >
            پیشفرض
          </Button>
        </div>

        <template #help>
          <p>
            این پیام زمانی که ربات از کاربر برای ارسال لینک درخواست دعوت اجباری می‌کند
            ارسال می‌شود.
          </p>
        </template>
      </MainSettingCard>

      <!-- force join -->

      <MainSettingCard
        :otherGroup="otherGroup"
        :loading="sending"
        @setSettings="submit('join_force', $event)"
      >
        <div class="text-lg font-bold">
          <img src="@/assets/images/icons8-rearrange-96.png" class="inline-block h-20" />
          پیام عضویت اجباری
        </div>

        <div class="grid xl:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-3 mt-3">
          <MnrTextarea
            v-model="group.msg.join_force"
            class="col-span-2"
            placeholder="متن پیام را وارد کنید"
          />
        </div>
        <div class="inline-block w-32 mt-3">
          <Button
            class="inline-block h-8"
            color="teal"
            @click="group.msg.join_force = defMsg.join_force"
          >
            پیشفرض
          </Button>
        </div>

        <template #help>
          <p>این پیام زمانی که ربات از کاربر درخواست عضویت اجباری می‌کند ارسال می‌شود.</p>
        </template>
      </MainSettingCard>

      <!-- lok messages -->

      <MainSettingCard
        :otherGroup="otherGroup"
        :loading="sending"
        @setSettings="submit('lock', $event)"
      >
        <div class="text-lg font-bold">
          <img src="@/assets/images/icons8-lock-96.png" class="inline-block h-20" />
          پیام قفل گروه
        </div>

        <div class="grid xl:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-3 mt-3">
          <MnrTextarea
            v-model="group.msg.lock"
            class="col-span-2"
            placeholder="متن پیام را وارد کنید"
          />
        </div>
        <div class="inline-block w-32 mt-3">
          <Button
            class="inline-block h-8"
            color="teal"
            @click="group.msg.lock = defMsg.lock"
          >
            پیشفرض
          </Button>
        </div>

        <template #help>
          <p>این پیام زمانی ارسال می‌شود که گروه قفل است</p>
        </template>
      </MainSettingCard>

      <!-- lok time messages -->

      <MainSettingCard
        :otherGroup="otherGroup"
        :loading="sending"
        @setSettings="submit('lock_time', $event)"
      >
        <div class="text-lg font-bold">
          <img src="@/assets/images/icons8-swing-time-96.png" class="inline-block h-20" />
          پیام قفل زماندار گروه
        </div>

        <div class="grid xl:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-3 mt-3">
          <MnrTextarea
            v-model="group.msg.lock_time"
            class="col-span-2"
            placeholder="متن پیام را وارد کنید"
          />
        </div>
        <div class="inline-block w-32 mt-3">
          <Button
            class="inline-block h-8"
            color="teal"
            @click="group.msg.lock_time = defMsg.lock_time"
          >
            پیشفرض
          </Button>
        </div>

        <template #help>
          <p>این پیام زمانی ارسال می‌شود که قفل زمان دار گروه فعال است</p>
        </template>
      </MainSettingCard>

      <!-- lok type messages -->

      <MainSettingCard
        :otherGroup="otherGroup"
        :loading="sending"
        @setSettings="submit('lock_type', $event)"
      >
        <div class="text-lg font-bold">
          <img src="@/assets/images/icons8-mms-96.png" class="inline-block h-20" />
          پیام قفل فایل ها و رسانه ها
        </div>

        <div class="grid xl:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-3 mt-3">
          <MnrTextarea
            v-model="group.msg.lock_type"
            class="col-span-2"
            placeholder="متن پیام را وارد کنید"
          />
        </div>
        <div class="inline-block w-32 mt-3">
          <Button
            class="inline-block h-8"
            color="teal"
            @click="group.msg.lock_type = defMsg.lock_type"
          >
            پیشفرض
          </Button>
        </div>

        <template #help>
          <p>این پیام برای قفل فایل ها و سایر موارد ارسال می‌شود.</p>
        </template>
      </MainSettingCard>

      <!-- spam messages -->

      <MainSettingCard
        :otherGroup="otherGroup"
        :loading="sending"
        @setSettings="submit('spam', $event)"
      >
        <div class="text-lg font-bold">
          <img src="@/assets/images/icons8-chat-96.png" class="inline-block h-20" />
          پیام اسپم و پیام تکراری
        </div>

        <div class="grid xl:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-3 mt-3">
          <MnrTextarea
            v-model="group.msg.spam"
            class="col-span-2"
            placeholder="متن پیام را وارد کنید"
          />
        </div>
        <div class="inline-block w-32 mt-3">
          <Button
            class="inline-block h-8"
            color="teal"
            @click="group.msg.spam = defMsg.spam"
          >
            پیشفرض
          </Button>
        </div>

        <template #help>
          <p>این پیام در صورت اسپم و پیام تکراری ارسال می‌شود</p>
        </template>
      </MainSettingCard>

      <!-- global spam messages -->

      <MainSettingCard
        :otherGroup="otherGroup"
        :loading="sending"
        @setSettings="submit('global', $event)"
      >
        <div class="text-lg font-bold">
          <img src="@/assets/images/icons8-module-96.png" class="inline-block h-20" />
          پیام امنیت سراسری
        </div>

        <div class="grid xl:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-3 mt-3">
          <MnrTextarea
            v-model="group.msg.global"
            class="col-span-2"
            placeholder="متن پیام را وارد کنید"
          />
        </div>
        <div class="inline-block w-32 mt-3">
          <Button
            class="inline-block h-8"
            color="teal"
            @click="group.msg.global = defMsg.global"
          >
            پیشفرض
          </Button>
        </div>

        <template #help>
          <p>
            این پیام برای مواردی که مربوط به امنیت سراسری است ارسال می‌شود. مثل حذف اسپم
            سراسری
          </p>
        </template>
      </MainSettingCard>

      <!-- bot checker messages -->

      <MainSettingCard
        :otherGroup="otherGroup"
        :loading="sending"
        @setSettings="submit('bot_checker', $event)"
      >
        <div class="text-lg font-bold">
          <img
            src="@/assets/images/icons8-facial-recognition-96.png"
            class="inline-block h-20"
          />
          پیام تایید هویت کاربران
        </div>

        <div class="grid xl:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-3 mt-3">
          <MnrTextarea
            v-model="group.msg.bot_confirm"
            class="col-span-2"
            placeholder="متن پیام را وارد کنید"
          />
        </div>
        <div class="inline-block w-32 mt-3">
          <Button
            class="inline-block h-8"
            color="teal"
            @click="group.msg.bot_confirm = defMsg.bot_confirm"
          >
            پیشفرض
          </Button>
        </div>

        <template #help>
          <p>این پیام هنگام درخواست تائید هویت برای کاربران ارسال می‌شود</p>
        </template>
      </MainSettingCard>

      <!-- remove link messages -->

      <MainSettingCard
        :otherGroup="otherGroup"
        :loading="sending"
        @setSettings="submit('link', $event)"
      >
        <div class="text-lg font-bold">
          <img
            src="@/assets/images/icons8-delete-link-96.png"
            class="inline-block h-20"
          />
          پیام حذف لینک
        </div>

        <div class="grid xl:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-3 mt-3">
          <MnrTextarea
            v-model="group.msg.link"
            class="col-span-2"
            placeholder="متن پیام را وارد کنید"
          />
        </div>
        <div class="inline-block w-32 mt-3">
          <Button
            class="inline-block h-8"
            color="teal"
            @click="group.msg.link = defMsg.link"
          >
            پیشفرض
          </Button>
        </div>

        <template #help>
          <p>این پیام هنگام حذف لینک ارسال می‌شود</p>
        </template>
      </MainSettingCard>

      <!-- filter messages -->

      <MainSettingCard
        :otherGroup="otherGroup"
        :loading="sending"
        @setSettings="submit('filter', $event)"
      >
        <div class="text-lg font-bold">
          <img
            src="@/assets/images/icons8-email-document-96.png"
            class="inline-block h-20"
          />
          پیام فیلتر پیام ها
        </div>

        <div class="grid xl:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-3 mt-3">
          <MnrTextarea
            v-model="group.msg.filter"
            class="col-span-2"
            placeholder="متن پیام را وارد کنید"
          />
        </div>
        <div class="inline-block w-32 mt-3">
          <Button
            class="inline-block h-8"
            color="teal"
            @click="group.msg.filter = defMsg.filter"
          >
            پیشفرض
          </Button>
        </div>

        <template #help>
          <p>این پیام زمانی که پیام کاربران فیلتر می‌شود ارسال می‌شود</p>
        </template>
      </MainSettingCard>

      <!-- text limit messages -->

      <MainSettingCard
        :otherGroup="otherGroup"
        :loading="sending"
        @setSettings="submit('text_limit', $event)"
      >
        <div class="text-lg font-bold">
          <img
            src="@/assets/images/icons8-request-service-96.png"
            class="inline-block h-20"
          />
          پیام محدودیت کاراکتر متن پیام
        </div>

        <div class="grid xl:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-3 mt-3">
          <MnrTextarea
            v-model="group.msg.text_limit"
            class="col-span-2"
            placeholder="متن پیام را وارد کنید"
          />
        </div>
        <div class="inline-block w-32 mt-3">
          <Button
            class="inline-block h-8"
            color="teal"
            @click="group.msg.text_limit = defMsg.text_limit"
          >
            پیشفرض
          </Button>
        </div>

        <template #help>
          <p>این پیام برای محدودیت کاراکتر در متن ارسال می‌شود</p>
        </template>
      </MainSettingCard>

      <!-- limit pm messages -->

      <MainSettingCard
        :otherGroup="otherGroup"
        :loading="sending"
        @setSettings="submit('limit_pm', $event)"
      >
        <div class="text-lg font-bold">
          <img src="@/assets/images/icons8-chat-room-96.png" class="inline-block h-20" />
          پیام محدودیت ارسال پیام
        </div>

        <div class="grid xl:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-3 mt-3">
          <MnrTextarea
            v-model="group.msg.limit_pm"
            class="col-span-2"
            placeholder="متن پیام را وارد کنید"
          />
        </div>
        <div class="inline-block w-32 mt-3">
          <Button
            class="inline-block h-8"
            color="teal"
            @click="group.msg.limit_pm = defMsg.limit_pm"
          >
            پیشفرض
          </Button>
        </div>

        <template #help>
          <p>این پیام برای محدودیت ارسال پیام ارسال می‌شود</p>
        </template>
      </MainSettingCard>

      <!-- warning messages -->

      <MainSettingCard
        :otherGroup="otherGroup"
        :loading="sending"
        @setSettings="submit('warning', $event)"
      >
        <div class="text-lg font-bold">
          <img src="@/assets/images/icons8-error-96.png" class="inline-block h-20" />
          پیام اخطار
        </div>

        <div class="grid xl:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-3 mt-3">
          <MnrTextarea
            v-model="group.msg.warning"
            class="col-span-2"
            placeholder="متن پیام را وارد کنید"
          />
        </div>
        <div class="inline-block w-32 mt-3">
          <Button
            class="inline-block h-8"
            color="teal"
            @click="group.msg.warning = defMsg.warning"
          >
            پیشفرض
          </Button>
        </div>

        <template #help>
          این پیام اخطار در پیام های دیگر قرار می‌گیرد . زمانی ارسال می‌شود که اخطار روشن
          باشد.
        </template>
      </MainSettingCard>

      <!-- submit all -->

      <MainSettingCard
        :otherGroup="otherGroup"
        :loading="sending"
        @setSettings="submit('all', $event)"
      >
        <div class="text-lg font-bold">
          <img src="@/assets/images/icons8-enter-key-96.png" class="inline-block h-20" />
          توجه کنید: ثبت همه
        </div>
        <p>
          شما در هر یک از بخش های بالا می‌توانید تنظیمات آن بخش را ذخیره کنید اما در این
          قسمت میتوانید تنظیمات تمام قسمت ها را به صورت یک جا ذخیره کنید
        </p>
      </MainSettingCard>
    </template>
  </BodySimple>
</template>

<script>
import BodySimple from "@/layouts/BodySimple.vue";
import Icon from "@/components/icon/Icon.vue";

import MnrNumber from "@/components/mnr/MnrNumber.vue";
import MnrTextarea from "@/components/mnr/MnrTextarea.vue";

import Button from "@/components/Button.vue";
import MainSettingCard from "@/components/MainSettingCard.vue";
import MnrCheckSlider from "@/components/mnr/MnrCheckSlider.vue";
import ErrorBox from "@/components/ErrorBox.vue";

export default {
  components: {
    BodySimple,
    Icon,
    Button,
    MnrNumber,
    MnrTextarea,
    MainSettingCard,
    MnrCheckSlider,
    ErrorBox,
  },
  data() {
    return {
      errors: [],
      firstLoading: true,
      group: {},
      defMsg: {},
      otherGroup: [],
      sending: false,
      alertTime: {
        sec: 0,
        min: 0,
      },
      tagHelper: false,
      tags: [
        {
          tag: ":grp:",
          info: "نام گروه",
        },
        {
          tag: ":name:",
          info: "نام کاربر",
        },
        {
          tag: ":noadd:",
          info: "قرار دادن دستور رفع محدودیت کاربر از دعوت",
        },
        {
          tag: ":name:xxx;",
          info: "نام کاربر با نام دلخواه به جای xxx",
        },
        {
          tag: ":uid:",
          info: "شناسه عددی کاربر (ID عددی کاربر)",
        },
        {
          tag: ":newuser:",
          info: "نام کاربری که به گروه وارد یا خارج می‌شود. (فقط در پیام های ورود و خروج)",
        },
        {
          tag: ":newuser:xxx;",
          info: "نام کاربری که به گروه وارد یا خارج می‌شود. با نام دلخواه به جای xxx (فقط در پیام های ورود و خروج)",
        },
        {
          tag: ":add:",
          info: "تعداد افرادی که باید دعوت کند",
        },
        {
          tag: ":added:",
          info: "تعداد افرادی که کاربر دعوت کرده",
        },
        {
          tag: ":addremain:",
          info: "تعداد افراد باقی‌مانده که کاربر باید دعوت کند",
        },
        {
          tag: ":addlink:",
          info: "تعداد افرادی که کاربر باید برای ارسال لینک دعوت کند",
        },
        {
          tag: ":addlinkremain:",
          info: "تعداد افراد باقی‌مانده که کاربر باید برای ارسال لینک دعوت کند",
        },
        {
          tag: ":addtime:",
          info: "زمانی که بعد از آن کاربر باید دوباره دعوت کند به روز",
        },
        {
          tag: ":chname:",
          info: "نام کانالی که کاربر باید برای پیام دادن در آن عضو شود",
        },
        {
          tag: ":chlink:",
          info: "نام کانال به صورت لینک که کاربر باید برای پیام دادن در آن عضو شود",
        },
        {
          tag: ":chid:",
          info: "کانال به صورت ID که کاربر باید برای پیام دادن در آن عضو شود",
        },
        {
          tag: ":slock:",
          info: "زمان شروع قفل به صورت ساعت و دقیقه (فقط در پیام قفل زماندار)",
        },
        {
          tag: ":elock:",
          info: "زمان پایان قفل به صورت ساعت و دقیقه (فقط در پیام قفل زماندار)",
        },
        {
          tag: ":acc:",
          info: 'لینک خرید اشتراک به صورت متنی با متن "خرید اشتراک"',
        },
        {
          tag: ":acc:xxx;",
          info: "لینک خرید اشتراک به صورت متنی با جایگزین xxx",
        },
        {
          tag: ":locktype:",
          info: "نوع فایل ورودی که قفل است (فقط در پیام قفل تایپ ها)",
        },
        {
          tag: ":locktypefa:",
          info: "نوع فایل ورودی که قفل است به فارسی (فقط در پیام قفل تایپ ها)",
        },
        {
          tag: ":min:",
          info: "حد اقل طول کاراکتر در متن",
        },
        {
          tag: ":max:",
          info: "حد اکثر طول کاراکتر در متن",
        },
        {
          tag: ":limittime:",
          info: "زمان مشخص شده در تنظیمات محدودیت ارسال پیام. اگر نوع محدودیت روزانه باشد 24 بر می‌گرداند",
        },
        {
          tag: ":limitpm:",
          info: "تعداد پیام مشخص شده در تنظیمات محدودیت ارسال پیام",
        },
        {
          tag: ":warningcount:",
          info: "تعداد اخطار های دریافتی",
        },
        {
          tag: ":warningmax:",
          info: "تعداد سقف اخطار های دریافتی تایین شده",
        },
        {
          tag: ":warning:",
          info: "متن پیام اخطار که در پیام های دیگر قرار میگیرد",
        },
      ],
      timeTags: [
        {
          tag: ":t-y:",
          info: "سال جاری به عدد",
        },
        {
          tag: ":t-j:",
          info: "ماه جاری به عدد",
        },
        {
          tag: ":t-jt:",
          info: "ماه جاری به حروف",
        },
        {
          tag: ":t-d:",
          info: "روز جاری به عدد",
        },
        {
          tag: ":t-w:",
          info: "نام روز هفته به حروف",
        },
        {
          tag: ":t-h:",
          info: "ساعت",
        },
        {
          tag: ":t-m:",
          info: "دقیقه",
        },
        {
          tag: ":t-s:",
          info: "ثانیه",
        },
      ],
    };
  },
  watch: {
    alertTime: {
      handler(val) {
        this.group.del_alert_time = val.min * 60 + val.sec;
      },
      deep: true,
    },
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      let $this = this;
      $this.firstLoading = true;
      $this.$axios
        .get("/api/group-bot-message/" + $this.$route.params.groupId, {
          params: {
            id: $this.$store.state.user.id,
          },
        })
        .then(function (response) {
          // console.log(response);
          $this.group = response.data.group;
          $this.defMsg = response.data.defMsg;
          $this.otherGroup = response.data.otherGroup;
          // $this.country = response.data.country;
          // $this.province = response.data.province;
          // $this.city = response.data.city;
          // $this.noAd = response.data.noAd;

          $this.alertTime.min = Math.floor($this.group.del_alert_time / 60);
          $this.alertTime.sec = $this.group.del_alert_time % 60;
        })
        .catch(function (error) {
          // console.log(error);
        })
        .then(function () {
          $this.firstLoading = false;
        });
    },
    submit(update, id = this.$route.params.groupId) {
      // console.log(update, id);
      let $this = this;
      $this.sending = update;
      // console.log(id, update);
      $this.errors = [];
      $this.$axios
        .put("/api/group-bot-message/" + $this.$route.params.groupId, {
          id: $this.$store.state.user.id,
          update: update,
          groupsId: id,
          group: $this.group,
        })
        .then(function (response) {
          // console.log(response);
          // $this.group = response.data.group;
          // $this.setting = response.data.setting;
          // $this.otherSetting = response.data.otherSetting;
          // $this.otherGroup = response.data.otherGroup;
          $this.$notify({ text: "تغییرات ذخیره شد", type: "success" });
        })
        .catch(function (error) {
          $this.errors = error.data;
          $this.$notify({ text: "خطا: تغییرات ذخیره نشد", type: "error" });
        })
        .then(function () {
          $this.sending = false;
        });
    },
  },
};
</script>

<style></style>
